<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import Initial from './Initial.vue'
    import CustomerRegister from './CustomerRegister.vue'
    import CustomerExpenditure from './CustomerExpenditure.vue'
    import CustomerResult from './CustomerResult.vue'
    import ProgressBar from '../components/ProgressBar.vue'
    import Footer from '../components/Footer.vue'
    import ModalRegister from '../components/ModalRegister.vue';
    import cidades from '../assets/js/cidades.json'

    let currentStep = ref(1)
    let form = ref({
      // Dados Pessoais
      name: '',
      phoneNumber: '',
      email: '',
      zipCode: '',
      city: '',
      state: '',
      country: 'Brasil',
      type: '',
      
      // Configuração
      zone: 'Sudeste',
      ibge: '3543402',
      system: 'ON-GRID',
      typeStructure: 0.76,
      typeRafter: 'Madeira',
      struture: 'Fibrocimento (fix. Madeira)',
      face: 1,
      shading: 1,
      phaseQtt: 2,
      phaseVoltage: '127',
      circuiteBreaker: '300',
      // Consumo
      avaregeCost: 0,
      monthlyConsumption: 350.0,
      rate: 0.0,
      cip: 0.0,
      freeArea: 100.0,
      rateDesabled: false,
      owner: true,
      document: '',
      campaignChannel: 'Calculadora',
      overpowerLimit: 1.3,
      irradiation: 0.0,
      overallEfficiency: 0.0,
      ratedPower: 0.0,
      // Resultado
      currentCount: 0.0,
      systemCount: 0.0,
      generateKWH: 0.0,
      differenceConsumer: 0.0,
      generateRage: 0.042,
      oneYearSpendWithSystem: 0.0,
      twentyFiveYearSpendWithSystem: 0.0,
      oneYearSavingsWithSystem: 0.0,
      twentyFiveYearSavingsWithSystem: 0.0,
      proposedValue: 0.0,
      returnInvestiment: 0.0,
      freight: 0.0,
      equipmentValue: 0.0,
      installationValue: 0.0,
      caValue: 0.0,
      // Sistema Recomendado
      markupDEYE: 1,
      markupService: 1.25,
      markupProduct: 1.8, 
      markupInstallation: 0.05,
      markupCA: 0.02,
      moduleTotal: 0.0,
      moduleQtt: 0.0,
      moduleArea: 0,
      transformationSecury: 0.10,
      inversorSobredin: 0.05,
      inversorFactor: 1.3,
      modulePotencial: 0.0,
      inversorPotencial: 0.0,
      inversorEntry: 0.0,
      possibleMagnificationValue: 0.0,

      compartilhadorId: null,
    })
    const products = ref([]);
    const route = useRoute()
    const router = useRouter()
    //let codeLink = window.location.pathname.split("/")[1] || null
    let codeLink = window.location.hash.split("/")[1] || "W5IHO8";
    // let codeLink = route.params.codeLink || null

    const fetchCompartilhadorId = async () => {
      if (!codeLink){
        form.value.compartilhadorId = null
        return console.log('Nenhum codeLink na URL.')
      }
      try {
        // Faz uma solicitação GET para a API
        const response = await axios.get('https://api.kinsol.com.br/v1/compartilhadores')
        
        // Verifica se a resposta da API foi bem-sucedida
        if (response.status === 200) {
          // Obtém a lista de compartilhadores da resposta da API
          let compartilhadores = response.data
          
          // Procura um compartilhador com base no codeLink
          let compartilhador = compartilhadores.find(compart => compart.code_link === codeLink)
          
          if (compartilhador) {
            // Se encontrar um compartilhador com o codeLink correspondente, define o compartilhadorId
            form.value.compartilhadorId = compartilhador.id
          } else {
            console.log("Nenhum compartilhador encontrado com o codeLink:", codeLink)
          }
        } else {
          console.log("Erro na solicitação à API")
        }
      } catch (error) {
        console.error("Erro ao acessar a API:", error)
      }
    }

    const nextStep = (pageNumber=0) => {
        if (pageNumber > 0) {
          currentStep.value = pageNumber
          return
        }

        currentStep.value++
    }
    const prevStep = () => {
        currentStep.value--
    }

    const undoSimulation = () => {
      currentStep.value = 2,
    
      form.value.name = '',
      form.value.phoneNumber = '',
      form.value.email = '',
      form.value.zipCode = '',
      form.value.city = '',
      form.value.state = '',
      form.value.country = 'Brasil',
      form.value.type = '',
      form.value.zone = 'Sudeste',
      form.value.ibge = '3543402',
      form.value.system = 'ON-GRID',
      form.value.typeStructure = 0.76,
      form.value.typeRafter = 'Madeira',
      form.value.struture = 'Fibrocimento (fix. Madeira)',
      form.value.face = 1,
      form.value.shading = 1,
      form.value.phaseQtt = 2,
      form.value.phaseVoltage = '127',
      form.value.circuiteBreaker = '300',
      form.value.avaregeCost = 0,
      form.value.monthlyConsumption = 350.0,
      form.value.rate = 0.0,
      form.value.cip = 0.0,
      form.value.freeArea = 100.0,
      form.value.rateDesabled = false,
      form.value.owner = true,
      form.value.document = '',
      form.value.campaignChannel = 'Calculadora',
      form.value.overpowerLimit = 1.3,
      form.value.irradiation = 0.0,
      form.value.overallEfficiency = 0.0,
      form.value.ratedPower = 0.0,
      form.value.currentCount = 0.0,
      form.value.systemCount = 0.0,
      form.value.generateKWH = 0.0,
      form.value.differenceConsumer = 0.0,
      form.value.generateRage = 0.042,
      form.value.oneYearSpendWithSystem = 0.0,
      form.value.twentyFiveYearSpendWithSystem = 0.0,
      form.value.oneYearSavingsWithSystem = 0.0,
      form.value.twentyFiveYearSavingsWithSystem = 0.0,
      form.value.proposedValue = 0.0,
      form.value.returnInvestiment = 0.0,
      form.value.freight = 0.0,
      form.value.equipmentValue =  0.0,
      form.value.installationValue = 0.0,
      form.value.caValue = 0.0,
      form.value.markupDEYE = 1,
      form.value.markupService = 1.25,
      form.value.markupProduct = 1.8,
      form.value.markupInstallation = 0.05,
      form.value.markupCA = 0.02,
      form.value.moduleTotal = 0.0,
      form.value.moduleQtt = 0.0,
      form.value.moduleArea = 0,
      form.value.transformationSecury = 0.10,
      form.value.inversorSobredin = 0.05,
      form.value.inversorFactor = 1.3,
      form.value.modulePotencial = 0.0,
      form.value.inversorPotencial = 0.0,
      form.value.inversorEntry = 0.0,
      form.value.possibleMagnificationValue = 0.0
    }

    const handleZipCode = (response) => {
      form.value.zipCode = response.cep.replace('-','')
      form.value.city = response.localidade
      form.value.state = response.uf
      form.value.ibge = response.ibge
    }
    const calculateCip = () => {
      let monthlyConsumption = form.value.monthlyConsumption
      if (monthlyConsumption >= 0 && monthlyConsumption <= 30)
        form.value.cip = 1.57
      else if (monthlyConsumption > 30 && monthlyConsumption <= 50)
        form.value.cip = 3.14
      else if (monthlyConsumption > 50 && monthlyConsumption <= 100)
        form.value.cip = 12.57
      else if (monthlyConsumption > 100 && monthlyConsumption <= 200)
        form.value.cip = 25.15
      else if (monthlyConsumption > 200 && monthlyConsumption <= 300)
        form.value.cip = 34.58
      else
        form.value.cip = 44.00
    }
    const calculateRatedPower = () => {
      let listCity = [...cidades.cidades]
      let annualCode = listCity.find(e => e.Codigo === form.value.ibge)
      form.value.irradiation = parseInt(annualCode.Anual) / 1000
      form.value.overallEfficiency = form.value.face * form.value.shading * form.value.typeStructure * 100 
      form.value.ratedPower = form.value.monthlyConsumption / (((form.value.overallEfficiency / 100) * 30) * form.value.irradiation)
    }
    const calculateProposonal = () => {
      // calculate current count
      form.value.currentCount = (form.value.monthlyConsumption * parseFloat(form.value.avaregeCost)) + form.value.cip
      // calculate difference consumer
      form.value.differenceConsumer = Math.max(form.value.monthlyConsumption - form.value.generateKWH, 0)
      // calculate system count
      let sum = 0
      sum += parseFloat(form.value.avaregeCost) * 50
      sum += form.value.cip
      sum += form.value.differenceConsumer
      sum += (form.value.generateKWH - (0.5 * form.value.monthlyConsumption)) * form.value.generateRage * parseFloat(form.value.avaregeCost)
      form.value.systemCount = parseFloat(sum.toFixed(2))
      // calculate one year savingsWith system
      form.value.oneYearSavingsWithSystem = (form.value.currentCount - form.value.systemCount) * 12
      // calculate twenty fiveYear savings with system
      form.value.twentyFiveYearSavingsWithSystem = parseFloat((form.value.oneYearSavingsWithSystem * (((Math.pow((1 + 0.1), 25)) - 1) / 0.1)).toFixed(2))
      // calculate one year spend with system
      form.value.oneYearSpendWithSystem = form.value.oneYearSavingsWithSystem * 12
      // calculate twenty five year spend with system
      form.value.twentyFiveYearSpendWithSystem = form.value.oneYearSavingsWithSystem * 12 * 25
    }
    const calculeteModule = () => {
      let modules = [...products.value.filter(e => e.Tipo==='Módulo Fotovoltaico' && e.Fornecedor==='DEYE')]
      let moduleSubTotal = 0.0
      let moduleTotal = 0.0
      let subTotal = 0.0
      let moduleQtt = 0.0
      
      for (let module of modules) {
        moduleQtt = Math.ceil(form.value.ratedPower / module.Potencia)
        moduleSubTotal = moduleQtt * module.PrecoCorrigido
        
        if ((moduleTotal === 0 && moduleSubTotal !== 0.0) && moduleSubTotal > moduleTotal) {
          moduleTotal = moduleSubTotal
          form.value.modulePotencial = parseFloat((moduleQtt * module.Potencia).toFixed(2))
          form.value.moduleQtt = moduleQtt
          form.value.moduleArea = Math.ceil((module.Area || 2.5) * moduleQtt)
        }

        // calculate generateKWH
        form.value.generateKWH = Math.ceil(form.value.modulePotencial * 30 * (form.value.irradiation) * (form.value.overallEfficiency / 100))
      }

      subTotal = parseFloat((moduleTotal * form.value.markupDEYE * form.value.markupProduct).toFixed(2))
      form.value.moduleTotal = subTotal
      form.value.proposedValue = subTotal
      form.value.equipmentValue = subTotal
    }
    const calculateInversor = () => {
      let listInversor = [...products.value.filter(e => {
        return e.Categoria === 'Inversor'
          && e.Fornecedor === 'DEYE'
          && e.Tipo.split(" ")[1] !== 'Trifásico'
          && e.PrecoCorrigido !== '0'
      })]
      let residualPotencial = form.value.modulePotencial
      const maxPower = Math.max(...listInversor.map(e => parseFloat(e.Potencia)))
      let greaterPotencial = {...listInversor.find(e => parseFloat(e.Potencia) === maxPower)}
      let subTotal = 0.0

      greaterPotencial.Potencia *= form.value.overpowerLimit

      let inversorQtt = Math.floor(residualPotencial / greaterPotencial.Potencia)
      greaterPotencial.Quantity = inversorQtt

      const difference = residualPotencial - (greaterPotencial.Potencia * inversorQtt)

      if (difference <= 0)
        return

      let listPotencial = [...listInversor.map(e => parseFloat(e.Potencia))]
      const minPower = getMinPower(listPotencial, difference)
      let lowerPotencial = {...listInversor.find(e => parseFloat(e.Potencia) === minPower)}
      lowerPotencial.Potencia *= form.value.overpowerLimit
      inversorQtt++
      lowerPotencial.Quantity = 1

      form.value.inversorPotencial = parseFloat(greaterPotencial.Potencia) * greaterPotencial.Quantity
      + parseFloat(lowerPotencial.Potencia) * lowerPotencial.Quantity

      subTotal = (parseFloat(greaterPotencial.PrecoCorrigido) * greaterPotencial.Quantity * form.value.markupProduct * form.value.markupDEYE)
        + (parseFloat(lowerPotencial.PrecoCorrigido) * lowerPotencial.Quantity * form.value.markupProduct * form.value.markupDEYE)

      let inversorEntry = 0.0
      if (greaterPotencial.Entradas == '')
        inversorEntry = (1 * greaterPotencial.Quantity) + 
        (1 * lowerPotencial.Quantity)
      else 
        inversorEntry = (greaterPotencial.Entradas * greaterPotencial.Quantity) + 
        (lowerPotencial.Entradas * lowerPotencial.Quantity)
      
      form.value.inversorEntry = inversorEntry
      form.value.equipmentValue += subTotal
      form.value.proposedValue += subTotal
    }
    const getMinPower = (listPotencial, difference) => {
      const filteredArray = listPotencial.filter(e => e > difference);

      return filteredArray.length > 0 ? Math.min(...filteredArray) : Math.max(...listPotencial)
    }
    const calculateProtector = () => {
      let inversorPotencial = form.value.inversorPotencial
      let protectorQtt = 2
      let protectorTotal = 0.0
      let listProtector = [...products.value.filter(e => {
          return e.Tipo==='Protetor surto CA'
            && e.Nome===(inversorPotencial > 200 ? '1100Vca 50kA/200kA' : 'SPW02-275-20')
        })]
      let subTotal = 0.0

      for (let protector of listProtector)
        protectorTotal = protector.PrecoCorrigido * protectorQtt * form.value.markupDEYE * form.value.markupProduct
      
      form.value.equipmentValue += subTotal
      form.value.proposedValue += protectorTotal
    }
    const calculateConnector = () => {
      let connectorEntry = form.value.inversorEntry
      let connectorQtt = Math.ceil(1.1 * connectorEntry)
      let connectorSutotal = 0.0
      let cableCCFootage = 25
      let cableCCQtt = 0
      let cableCCRedSubtotal = 0.0
      let cableCCBlackSubtotal = 0.0
      let subTotal = 0.0

      let connectorList = [...products.value.filter(e => {
          return e.Tipo==='Conector'
            && e.Fornecedor==='DEYE'
      })]

      for (let connector of connectorList)
        connectorSutotal = connector.PrecoCorrigido * connectorQtt * form.value.markupDEYE * form.value.markupProduct
      
      
      cableCCQtt = connectorEntry * cableCCFootage
      let cableCCList = [...products.value.filter(e => {
        return e.Tipo==='Cabo CC'
          && e.Fornecedor==='DEYE'
      })]

      for (let cableCC of cableCCList) {
        if (cableCC.Nome === 'CABO SOLAR AMPHESOLAR 6MM 1800V DC VERMELHO - AMPHENOL') 
          cableCCRedSubtotal = cableCC.PrecoCorrigido * cableCCQtt * form.value.markupDEYE * form.value.markupProduct
        else 
          cableCCBlackSubtotal = cableCC.PrecoCorrigido * cableCCQtt * form.value.markupDEYE * form.value.markupProduct
      }

      subTotal = connectorSutotal + cableCCRedSubtotal + cableCCBlackSubtotal
      form.value.proposedValue += subTotal
      form.value.equipmentValue += subTotal
    }
    const calculateRoofStructure  = () => {
      let roofQtt3 = 0.0
      let roofQtt4 = 0.0
      let divisorFour = 0.0
      let roof3Subtotal = 0.0
      let roof4Subtotal = 0.0
      let subTotal = 0.0

      roofQtt4 = Math.floor(form.value.moduleQtt / 4)
      divisorFour = form.value.moduleQtt % 4
      
      if (divisorFour == 0) { 
        roofQtt3 = 0
      } else if (divisorFour == 1) {
          roofQtt3 = 3
          roofQtt4 -= 2
      } else if (divisorFour == 2) { 
        roofQtt3 = 1
      } else if (divisorFour == 3) {
        roofQtt3 = 1
      }

      let roofList = [...products.value.filter(e => {
        return e.Nome=== "Fibrocimento 3 módulos em retrato (fix. madeira)"
        || e.Nome=== "Fibrocimento 4 módulos em retrato (fix. madeira)"
        && e.Fornecedor==='DEYE'

      })]

      for (let roof of roofList) {
        if (roofQtt3 > 0 && roof.Nome === "Fibrocimento 3 módulos em retrato (fix. madeira)") {
          roof3Subtotal = roof.PrecoCorrigido * roofQtt3 * form.value.markupDEYE * form.value.markupProduct
        }

        if (roofQtt4 > 0 && roof.Nome === "Fibrocimento 4 módulos em retrato (fix. madeira)") {
          roof4Subtotal = roof.PrecoCorrigido * roofQtt4 * form.value.markupDEYE * form.value.markupProduct
        }
      }
      
      subTotal = roof3Subtotal + roof4Subtotal
      form.value.proposedValue += subTotal
      form.value.equipmentValue += subTotal
    }
    const calculeDischarge = () => {
      let DischargeSubtotal = 0.0
      let modulePotencialAux = form.value.moduleQtt
      if (modulePotencialAux < 10) {
        DischargeSubtotal = 300
      } else {
        modulePotencialAux -= 10
        DischargeSubtotal = 300 + (modulePotencialAux * 10)
      }
      
      form.value.proposedValue += DischargeSubtotal
    }
    const calculeAdequation = () => {
      let circuitBreakerRequired = Math.ceil(1000 * (parseFloat(form.value.inversorPotencial) / parseFloat(form.value.overpowerLimit)) / (parseFloat(form.value.phaseQtt) * parseFloat(form.value.phaseVoltage)))
      let totalAdjustmentValue = 0.0
      let substation = parseFloat(form.value.inversorPotencial) / form.value.overpowerLimit
      let totalSubstationValue = 0.0
      let nameAdequation = null;

      if (form.value.phaseQtt === 1)
          nameAdequation = 'Bifásico - Kinsol'
      else if (form.value.phaseQtt === 2) {
          if (circuitBreakerRequired <= 100)
              nameAdequation = 'Trifásico 100A - Kinsol'
          else if (circuitBreakerRequired >= 100)
              nameAdequation = 'Trifásico 125/150A - Kinsol'
          else if (circuitBreakerRequired >= 150)
              nameAdequation = 'Trifásico 200A - Kinsol'
      } else {
          if (circuitBreakerRequired <= 100)
              nameAdequation = 'Disjuntor - 100A - Kinsol'
          else if (circuitBreakerRequired >= 100)
              nameAdequation = 'Disjuntor - 150A - Kinsol'
          else if (circuitBreakerRequired >= 150)
              nameAdequation = 'Disjuntor - 200A - Kinsol'
      }
      
      const adequation = products.value.find(e => e.Nome === nameAdequation)

      form.value.proposedValue += (parseFloat(adequation.PrecoCorrigido || totalAdjustmentValue)) * form.value.markupService
    }
    const calculeFreight = () => {
      let regionValue = 0

      switch (form.value.zone) {
        case 'Sul':
          regionValue = 0.02
          break
        case 'Sudeste':
          regionValue = 0.03
          break
        case 'Centro-Oeste':
          regionValue = 0.04
          break
        case 'Nordeste':
          regionValue = 0.06
          break
        case 'Norte':
          regionValue = 0.07
          break
      }
      
      form.value.freight = form.value.equipmentValue * regionValue
    }
    const calculateCA = () => {
      let subTotal = form.value.proposedValue + form.value.freight

      form.value.caValue = (subTotal / (1 - form.value.markupInstallation - form.value.markupCA) - subTotal) / 
      (form.value.markupInstallation + form.value.markupCA) * 
      form.value.markupCA

      form.value.proposedValue += form.value.caValue
    }
    const calculateInstallation = () => {
      let subTotal = form.value.proposedValue + form.value.freight

      form.value.installationValue = 
        (subTotal / (1 - form.value.markupInstallation - form.value.markupCA)
        - subTotal) / (form.value.markupInstallation + form.value.markupCA)
        * form.value.markupInstallation

      form.value.proposedValue += form.value.installationValue
    }
    const possibleMagnification = () => {
      let possibleMagnificationCalcule1 = parseFloat(form.value.inversorPotencial) - parseFloat(form.value.modulePotencial)
      let possibleMagnificationCalcule2 = parseFloat(form.value.modulePotencial) / parseFloat(form.value.moduleQtt)
      form.value.possibleMagnificationValue = Math.floor(possibleMagnificationCalcule1 / possibleMagnificationCalcule2 )
      
    }
    const calculatePayBack = () => {
      form.value.returnInvestiment = parseFloat((form.value.proposedValue / form.value.oneYearSavingsWithSystem).toFixed(1)) 
    }
    const sumFreightToProposed = () => {
      form.value.proposedValue += form.value.freight
    }

    const performCalculation = () => { 
      calculateRatedPower()
      calculeteModule()
      calculateProposonal()
      calculateInversor()
      calculateProtector()
      calculateConnector()
      calculateRoofStructure()
      calculeDischarge()
      calculeFreight()
      calculateCA()
      calculateInstallation()
      possibleMagnification()
      calculatePayBack()
      sumFreightToProposed()

      currentStep.value = 4
      router.replace({ name: 'Obrigado' })
    }

    const getProducts = async () => {
      try {
        const response = await axios.get('https://api.kinsol.com.br/v1/productsv2/list');
        products.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar os produtos:', error);
      }
    };

    onMounted( async () => {
      await getProducts();
      fetchCompartilhadorId();
      calculateCip();
    })
</script>

<template>
  <div>
    <ProgressBar :current-step="currentStep"
      ></ProgressBar>
      <div v-if="currentStep === 1">
        <Initial @set-page="nextStep()"></Initial>
      </div>
      <div v-if="currentStep === 2">
        <CustomerRegister :form="form"
          @set-page="(event) => event ? nextStep() : prevStep()"
          @set-zipcode="(response) => handleZipCode(response)"
        ></CustomerRegister>
      </div>
      <div v-if="currentStep === 3">
        <CustomerExpenditure :form="form" @perfom-calculation="performCalculation"
        @set-page="(event) => event ? nextStep() : prevStep()"
        ></CustomerExpenditure>
      </div>
      <div v-if="currentStep === 4">
        <CustomerResult :form="form"
        @set-page="undoSimulation()"></CustomerResult>
      </div>
      <Footer></Footer>
      <ModalRegister :form="form" @set-step="nextStep(4)"></ModalRegister>
    </div>
</template>
